import * as React from "react";
import Header from '../header/header';
import Mainlib from '../nav-library';


export default class Library extends React.Component {
    render() {
        return (
            <div>
                <Header />
                <Mainlib />
                

            </div>
        );
    }
}