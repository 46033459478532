import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideUpLoader } from '../../reducers/uploadReducer';
import UploaderFile from './uploaderFile';

const Uploader = () => {

    const files = useSelector(state => state.upload.files)
    const isVisible = useSelector(state => state.upload.isVisible)
    const dispatch = useDispatch()

    return( isVisible &&
        <div className='uploader'>
            <div className='uploader-title' >Downloads
            <button className='uploader-close' onClick={() => dispatch(hideUpLoader()) } >X</button>
            <hr/>
            </div>
            

            {files.map(file => 
            
            <UploaderFile key={file.id} file={file} />

            )}

        </div>
    );
};

export default Uploader