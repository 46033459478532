import * as React from "react";
import { BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import Library from '../library/library';
import Profile from '../profile/profile';
import Register from '../register/register';
import Auth from "../auth/auth";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../action/user";


function Window() {

    const isAuth = useSelector(state => state.user.isAuth)
    const dispatch = useDispatch()
    React.useEffect(() => {
        dispatch(auth())
    }, [])
    

   
    return (
        <div className="wrap">
            {!isAuth ?
            <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Auth />} />
                <Route path="/register" element={<Register />} />
                <Route path="*" element={<Navigate to="/login" replace/>}/>
                
            </Routes>

        </BrowserRouter>
        :
        <BrowserRouter>
            <Routes>
               
                <Route index exact path="/library" element={<Library />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="*" element={<Navigate to="/library" replace/>} />
                
            </Routes>
        </BrowserRouter>
        }
                
                


        </div>

    );
}



export default Window;

