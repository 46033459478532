import API from '../utills/API'
import { setUser } from '../reducers/userReducer'



export const registration = async (username, email, password) => {

    try {
        const response = await API.post('/api/auth/registration', {
        username,
        email,
        password
        }) 
        alert(response.data.message)
    } catch(e) {
        console.log(e)
        alert(e.response.data.message)
    }
    
}

export const login = (username, email, password) => {
    return async dispatch => {

        try {
            const response = await API.post('/api/auth/login', {
            username,
            email,
            password
            })
            dispatch(setUser(response.data.user))
            localStorage.setItem('token', response.data.token) 
    
        } catch(e) {
            console.log(e)
            alert(e.response.data.message)
        }
    }

   
    
}

export const auth = () => {
    return async dispatch => {

        try {
            const response = await API.post('/api/auth/auth',{}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            dispatch(setUser(response.data.user))
            localStorage.setItem('token', response.data.token) 
    
        } catch(e) {
            console.log(e)
            localStorage.removeItem('token')
        }
    }

   
    
}

