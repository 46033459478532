import * as React from "react";
import { useSelector } from "react-redux";




const Shield = (props) => {
    const shieldDisplay = useSelector(state => state.files.shieldDisplay)
    return (
        <div className="shield" style={{ display: shieldDisplay }} >
            {props.children}
        </div>
    )
}

export default Shield
