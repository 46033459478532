import * as React from "react";
import '../../index.css';
import NewFolder from '../newfolder/new-folder'
import FileItem from "../file-item/file-item";
import { useDispatch, useSelector } from "react-redux";
import { getFile, getFiles, updateShieldDisplay, openDirHandler, downloadFile, deleteFile, searchFiles, showFile } from "../../action/file"
import { useEffect } from "react";
import { useState } from "react";
import { pushToStack } from "../../reducers/fileReducer";
import { uploadFile } from "../../action/file";
import Uploader from "../uploader/uploader";
import '../../anim-loader.css'
import '../../adaptive-950px.css'
import { showLoader } from "../../reducers/appReducer";




const Mainlib = () => {

    const dispatch = useDispatch();
    const currentDir = useSelector(state => state.files.currentDir);
    const loader = useSelector(state => state.app.loader);
    const myFiles = useSelector(state => state.files.files.files);
    const [dragEnter, setDragEnter] = useState(false);
    const [searchName, setSearchName] = useState('')
    const [searchTimeout, setSearchTimeout] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);
    const [checked, setChecked] = useState(false);
    const [imgFull, setImgFull] = useState(false);

    useEffect(() => {
        dispatch(getFiles(currentDir));

    }, []);

    function createFolder() {
        dispatch(updateShieldDisplay('flex'));
    }

    function backOpenDir() {
        //console.log('ку');
        dispatch(getFile(currentDir)).then((result) => {
            dispatch(openDirHandler(result.parent));
        });

    }

    function openDir(file) {
        if (file.type === 'dir') {
            dispatch(openDirHandler(file._id));
            dispatch(pushToStack(currentDir));
        } else if (file.type === 'png' || file.type === 'jfif' || file.type === 'gif' || file.type === 'jpg') {
            showFile(file).then(fileData => {
                file.preview = fileData;
                setSelectedFile(file);
            });
        }

    }

    function fileUploadHandler(event) {
        const files = [...event.target.files]
        files.forEach(file => dispatch(uploadFile(file, currentDir)))

    }

    function dragEnterHandler(event) {
        event.preventDefault()
        event.stopPropagation()
        setDragEnter(true)
    }

    function dragLeaveHandler(event) {
        event.preventDefault()
        event.stopPropagation()
        setDragEnter(false)
    }

    function dropHandler(event) {
        event.preventDefault()
        event.stopPropagation()
        let files = [...event.dataTransfer.files]
        files.forEach(file => dispatch(uploadFile(file, currentDir)))
        setDragEnter(false)
    }

    function downloadClickHandler(e, file) {
        e.stopPropagation()
        downloadFile(file)
    }

    function deleteClickHandler(e, file) {
        e.stopPropagation()
        dispatch(deleteFile(file))
    }


    function searchChangeHandler(e) {
        setSearchName(e.target.value)
        if (searchTimeout !== false) {
            clearTimeout(searchTimeout)
        }
        dispatch(showLoader())
        if (e.target.value !== '') {
            setSearchTimeout(setTimeout((value) => {
                dispatch(searchFiles(value));
            }, 500, e.target.value))
        } else {
            dispatch(getFiles(currentDir))
        }


    }
    function checkChangeHandler() {
        setChecked(!checked);
    }

    function imgFullScreen() {
        setImgFull(!imgFull);
        console.log(imgFull);
    }

    return !dragEnter ? <main className={!dragEnter ? 'main-visible' : 'main-hidden'} onDragEnter={dragEnterHandler} onDragLeave={dragLeaveHandler} onDragOver={dragEnterHandler}>
        <div className="loader-screen" style={{ visibility: loader === true ? 'visible' : 'hidden', display: loader === true ? 'inherit' : 'none' }}>
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div >

        <div className="filter-blur">
            <div className="background-library">
            </div>
            <div className="library-nav">
                <label htmlFor="upload-file" className="upload-file">Upload File</label>
                <input multiple={true} onChange={(event) => fileUploadHandler(event)} type="file" id="upload-file" className="upload-file"></input>
                <div className="library-li">
                    <form>
                        <input type="search" name="q" className="border-search" placeholder="Find folder" value={searchName} onChange={e => searchChangeHandler(e)} />

                    </form>
                    <p>Your folders</p>
                    <hr />
                    <div className="width-box-edit" >
                        <button onClick={createFolder} className="new-folder" type="submit"  >Create new folder</button>
                        <label className="box-check">
                            <input type="checkbox" checked={checked} onChange={() => checkChangeHandler()} name="k" className="checked" /><span className="switch"></span>
                        </label>
                    </div>

                    <button className={currentDir === null ? 'back-hidden' : 'back-inherit'} onClick={backOpenDir}  >Go Back</button>
                    <ul type="none">
                        {myFiles ? myFiles.map(file => <FileItem showExtraActions={checked} selected={selectedFile && file._id === selectedFile._id} key={file._id} onClick={() => openDir(file)} file={file} onDownload={(e) => downloadClickHandler(e, file)} onDelete={(e) => deleteClickHandler(e, file)} />) : <li><p>Not Found</p></li>}
                    </ul>
                </div>
            </div>
        </div>

        <div className="library-photo">
            <div className="navigation">
                <hr />
                <div className="photo-section">
                    <img onClick={imgFullScreen} className={imgFull ? 'img-full-screen' : 'default-img'} src={`data: ${selectedFile ? 'image/png;base64,' + selectedFile.preview : 'image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}`} />
                </div>
            </div>
        </div>

        <NewFolder />
        <Uploader />



    </main>
        :
        <div className={dragEnter ? 'drop-area-visible' : 'drop-area-hidden'} onDrop={dropHandler} onDragEnter={dragEnterHandler} onDragLeave={dragLeaveHandler} onDragOver={dragEnterHandler}>drop files here</div>


}


export default Mainlib;


