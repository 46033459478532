import * as React from "react";
import '../../index.css'

const Input = (props) => {

    return (
        <div>
            <input className="input-main" onChange={(event) => props.setValue(event.target.value)}
                value={props.value}
                type={props.type}
                placeholder={props.placeholder} />
        </div>

    );
};


export default Input;

