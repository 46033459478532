import React from 'react';
import { useDispatch } from 'react-redux';
import { removeUploadFile } from '../../reducers/uploadReducer';


const UploaderFile = ({ file }) => {
    const dispatch = useDispatch()
    return (
        <div className='uploader-file-item' >
            <div className='uploader-file-header' >
                <div className='"uploader-file-name' >{file.name}</div>
                <button className='uploader-file-remove' onClick={() => dispatch(removeUploadFile(file.id)) } >X</button>
                </div>

                <div className='uploader-file-progress-bar'>
                    <div className='uploader-file-uploader-bar' style={({width: file.progress + "%"})} >
                    <div className='uploader-file-persent' >{file.progress}%</div>
                    </div>
                    
            </div>

        </div>
    );
};

export default UploaderFile