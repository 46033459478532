const SET_FILES = 'SET_FILES'
const SET_CURRENT_DIR = 'SET_CURRENT_DIR'
const ADD_FILE = 'ADD_FILE'
const SET_SHIELD_DISPLAY = 'SET_SHIELD_DISPLAY'
const DELETE_FILE = 'DELETE_FILE'
const PUSH_TO_STACK = 'PUSH_TO_STACK'
const POP = 'POP'



const defaultState = {
    files: [],
    currentDir: null,
    shieldDisplay: 'none',
    dirStack: []

}

export default function fileReducer(state = defaultState, action) {
    switch(action.type) {
        case SET_FILES: return {...state, files: action.payload}
        case SET_CURRENT_DIR: return {...state, currentDir: action.payload}
        case ADD_FILE: {
            const newstate = {...state, files: {...state.files, files:[...state.files.files , action.payload]}  };
            return newstate;
        }
        case SET_SHIELD_DISPLAY: return {...state, shieldDisplay: action.payload}
        case PUSH_TO_STACK: return {...state, dirStack: [... state.dirStack, action.payload]}
        case POP: return {...state, setCurrentDir: action.payload}
        case DELETE_FILE: return {...state, files: {...state.files, files:state.files.files.filter(file => file._id !==action.payload)}}

        default:
            return state

    }
}

export const setFiles = (files) => ({type: SET_FILES, payload: files})
export const setCurrentDir = (dir) => ({type: SET_CURRENT_DIR, payload: dir})
export const addFile = (file) => ({type: ADD_FILE, payload: file})
export const setShieldDisplay = (display) => ({type: SET_SHIELD_DISPLAY, payload: display})
export const pushToStack = (dir) => ({type: PUSH_TO_STACK, payload: dir})
export const pop = (dir) => ({type: POP, payload: dir})
export const deleteFileAction = (dirId) => ({type: DELETE_FILE, payload: dirId})