import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Shield from "../shield/shield";
import { createDir, updateShieldDisplay } from "../../action/file";
import { useState } from "react";
import { setShieldDisplay } from "../../reducers/fileReducer";
import Input from "../input/input"







const NewFolder = () => {
    const dispatch = useDispatch()
    const currentDir = useSelector(state => state.files.currentDir)
    const [dirName, setDirName] = useState('')




    function createFolder() {
        dispatch(createDir(currentDir, dirName))
        setDirName('')
        dispatch(setShieldDisplay('none'))

    }

    function closeFolder() {

        dispatch(updateShieldDisplay('none'))
    }




    return (
        <div>
            <Shield>
                <div className="new-folder-dialog"  >
                    <div className="create-folder">
                        <p className="exit-new-folder" onClick={() => closeFolder()} >X</p>
                        <p>Create Folder</p>
                        <Input type="text" placeholder="Best photo in 2022" value={dirName} setValue={setDirName} />
                        <button type="submit" onClick={() => createFolder()}  >Create</button>

                    </div>
                </div>
            </Shield>
        </div>
    )
}

export default NewFolder;

