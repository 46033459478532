
import { useDispatch } from "react-redux";
import "../../index.css"
import React, { useState } from 'react';
import Input from "../input/input";
import { login } from "../../action/user";
import Header from "../header/header";

const Auth = () => {
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const dispatch = useDispatch()


    return (
        <div>
            <Header/>
            <div className="block-auth">
                <div className="form-login">
                    <div>
                        <p>Login</p>
                        <Input value={username} setValue={setUsername} type="text" placeholder="Alex" />
                    </div>
                    <div>
                        <p>Email</p>
                        <Input value={email} setValue={setEmail} type="email" placeholder="email222@mail.ru" />
                    </div>
                    <div>
                        <p>Password</p>
                        <Input value={password} setValue={setPassword} type="password" />
                    </div>
                    
                   
                    

                    <button onClick={()  => dispatch(login(username,email, password)) }>Login</button>
                </div>
            </div>
        </div>

    )
}

export default Auth;


