
import '../../index.css';
import Input from "../input/input";
import React, { useState } from 'react';
import { registration } from '../../action/user';
import Header from '../header/header';

const Register = () => {
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")


    return (
        <div>
            <Header />
            <main>
                <div className="reg-body">
                    <div className="form-reg">

                        <div>
                            <p>Login</p>

                            <Input value={username} setValue={setUsername} type="text" placeholder="Alex" />
                        </div>


                        <div>
                            <p>Email</p>

                            <Input value={email} setValue={setEmail} type="email" placeholder="email222@mail.ru" />
                        </div>


                        <div>
                            <p>Password</p>

                            <Input value={password} setValue={setPassword} type="password" />

                        </div>


                        <br />

                        <button onClick={() => registration(username, email, password)} >Register</button>

                    </div>

                </div>
            </main>

        </div>

    );


}

export default Register;










