import * as React from "react";
import {useSelector } from "react-redux";
import Header from "../header/header";



const Profile = () => {
    const currentUser = useSelector(state => {
        console.log(state)
        return state.user.currentUser
        
    });

    const isAuth = useSelector(state => state.user.isAuth);
    console.log(currentUser)
    return (
        <div>
            <Header />
            <main>
                <div className="profile-block">
                    <h1>Personal information</h1>
                    <p className="info-text" >login</p>
                    <div className="profile-item">
                         
                        <p>{isAuth ? currentUser.username : 'You are not login'}</p>
                    </div>
                    <p className="info-text" >email</p> 
                    <div className="profile-item">
                        
                        <p>{isAuth ? currentUser.email : 'You are not login'}</p>
                    </div>

                </div>

            </main>
        </div>
    )
}
export default Profile
