import * as React from "react";
import '../../index.css';
import dirLogo from "../../assets/icon/icons8-file-folder-48.png"
import fileLogo from "../../assets/icon/icons8-file-50.png"
import deleteLogo from "../../assets/icon/delete.png"
import downloadLogo from "../../assets/icon/download.png"

const FileItem = ({ onClick, file, onDownload, onDelete, selected, showExtraActions }) => {

    return ( 
            <li onClick={onClick} className={selected ? "file-selected file-anim" : "file-item file-anim"}>
                <img className="img-file-folder" src={file.type === 'dir' ? dirLogo : fileLogo} /> {file.name}
                {file.type !== "dir" && <button onClick={onDownload} className={`file-but-download ${ showExtraActions ? " visible" : ''}` } ><img src={downloadLogo} /></button>}
                <button onClick={onDelete} className={`file-but-delete ${ showExtraActions ? " visible" : ''}`} ><img src={deleteLogo} /></button>
            </li>
    )
}

export default FileItem;