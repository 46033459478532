import API from '../utills/API'
import { setFiles, addFile, setShieldDisplay, setCurrentDir, deleteFileAction } from '../reducers/fileReducer'
import { addUpLoadFile, changeUploadFile, showUpLoader } from "../reducers/uploadReducer";
import { v4 as uuidv4 } from 'uuid';
import { hideLoader, showLoader } from "../reducers/appReducer";
import { Buffer } from 'buffer';

export function getFile(fileId) {
    return async dispatch => {
        try {
            const response = await API.get(`/api/file?id=${fileId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            return response.data.file;
        } catch (e) {
            console.log(e);
        }
    }
}

export function getFiles(dirId) {
    return async dispatch => {
        try {
            dispatch(showLoader())
            const response = await API.get(`/api/file${dirId ? '?parent=' + dirId : ''}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            console.log(response.data)
            dispatch(setFiles(response.data))
        } catch (e) {
            alert(e.response.data.message)
        } finally {
            dispatch(hideLoader())
        }
    }
}

export function createDir(dirId, name) {
    return async dispatch => {
        try {
            const response = await API.post(`/api/file`, {
                name,
                parent: dirId,
                type: 'dir'
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            console.log(response.data)
            dispatch(addFile(response.data.file))
        } catch (e) {
            console.log(e)
        }
    }
}

export function updateShieldDisplay(value) {
    return dispatch => {
        dispatch(setShieldDisplay(value));
    }
}
export const openDirHandler = (fileId) => {
    return dispatch => {
        dispatch(setCurrentDir(fileId))
        dispatch(getFiles(fileId))
    }
}

export function uploadFile(file, dirId) {
    return async dispatch => {
        try {
            const formData = new FormData()
            formData.append('file', file)
            if (dirId) {
                formData.append('parent', dirId)
            }
            const uploadFile = { name: file.name, progress: 0, id: uuidv4() }
            dispatch(showUpLoader())
            dispatch(addUpLoadFile(uploadFile))
            const response = await API.post(`/api/file/upload`, formData, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                onUploadProgress: progressEvent => {
                    console.log('ghdhdfhshd', progressEvent)
                    const totalLength = progressEvent.event.lengthComputable ? progressEvent.event.total : progressEvent.event.target.getResponseHeader('content-length') || progressEvent.event.target.getResponseHeader('x-decompressed-content-length');
                    console.log('total', totalLength)
                    if (totalLength) {
                        uploadFile.progress = Math.round((progressEvent.loaded * 100) / totalLength)
                        dispatch(changeUploadFile(uploadFile))
                    }
                }

            });

            dispatch(addFile(response.data))
        } catch (e) {
            console.log(e)
        }
    }
}

export async function downloadFile(file) {

    const response = await API.get(`/api/file/download?id=${file._id}`, {
        responseType: 'blob',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    if (response.status === 200) {
        const blob = response.data
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = downloadUrl
        link.download = file.name
        document.body.appendChild(link)
        link.click()
        link.remove()
    }
}

export async function showFile(file) {

    const response = await API.get(`/api/file/download?id=${file._id}`, {
        responseType: "arraybuffer",
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    });
    if (response.status === 200) {
        let d = Buffer.from(response.data).toString('base64');
        return d;
    }
    throw response;
}

export function deleteFile(file) {
    return async dispatch => {
        try {
            const response = await API.delete(`/api/file?id=${file._id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            dispatch(deleteFileAction(file._id))
        } catch (e) {
            console.log(e)
            alert('Directory is not empty')
        }
    }
}

export function searchFiles(search) {
    return async dispatch => {
        try {
            const response = await API.get(`/api/file/search?search=${search}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            dispatch(setFiles(response.data))
        } catch (e) {
            console.log(e)
        } finally {
            dispatch(hideLoader())
        }
    }
}
