import * as React from "react";
import '../../index.css';
import {NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../reducers/userReducer";
import exitLogo from "../../assets/icon/free-icon-exit-1286853.png"

const Header = () => {
    const isAuth = useSelector(state => state.user.isAuth)
    const dispatch = useDispatch()

        return (
            <header className="header-reg">
                <div className="header-nav">
                    {isAuth && <NavLink to="/library">Library</NavLink> }
                    {!isAuth && <div className="login"><NavLink to="/login">Login</NavLink></div>}
                    {!isAuth && <div className="login"><NavLink to="/register">Registration</NavLink></div>}
                    {isAuth && <NavLink to="/profile">Profile</NavLink> }
                    {isAuth && <div className="logout" onClick={() => dispatch(logout()) } >Выход <img src={exitLogo} /> </div> }
                </div>
            </header>
        )
    }


export default Header;