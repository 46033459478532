import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from "react-redux";
import Window from './components/window/window';
import { store } from "./reducers";

ReactDOM.render(
    <Provider store={store}>
        <Window />
    </Provider>,
    document.getElementById('root'))



















